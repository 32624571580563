import useApi from './api'
import useUser from '../state/useUser'

export const __redirectToSupportCenter =
  (_window) => (user, api) => async (articleId, onRedirect) => {
    const { redirectUrl, authenticationSAML } = await api.fetchSupportCenterRedirect(
      user.currentCompany.id
    )
    const body = { 'idpConfig.recipient': redirectUrl, SAMLResponse: authenticationSAML }
    const target = 'help'

    if (articleId) {
      Object.assign(body, { RelayState: getRelayState(redirectUrl, `article/${articleId}`) })
    }

    _window.open('', target)
    formPost(redirectUrl, body, target, onRedirect)
  }

export const __redirectToSupportCenterPage =
  (_window) => (user, api) => async (page, onRedirect) => {
    const { redirectUrl, authenticationSAML } = await api.fetchSupportCenterRedirect(
      user.currentCompany.id
    )
    const target = 'help'
    const body = {
      'idpConfig.recipient': redirectUrl,
      SAMLResponse: authenticationSAML,
      RelayState: getRelayState(redirectUrl, page)
    }

    _window.open('', target)
    formPost(redirectUrl, body, target, onRedirect)
  }

function getRelayState(redirectUrl, path) {
  const url = new URL(redirectUrl)
  const pathParts = url.pathname.split('/')
  // any parts before the tail of the pathname should be used as the RelayState prefix
  const prefix = pathParts.slice(1, pathParts.length - 1).join('/')
  return `${prefix.length > 0 ? prefix + '/' : ''}s/${path}`
}

function formPost(url, data, target, onSubmit) {
  const form = Object.entries(data).reduce((form, [k, v]) => {
    form.appendChild(PostInput(k, v))
    return form
  }, PostForm(url, target))

  if (onSubmit) form.addEventListener('submit', (e) => onSubmit(e, data))

  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}

function PostForm(action, target) {
  const form = document.createElement('form')
  return Object.assign(form, {
    action,
    target,
    method: 'POST',
    style: { display: 'none' }
  })
}

function PostInput(name, value) {
  const input = document.createElement('input')
  return Object.assign(input, {
    name,
    value,
    type: 'hidden'
  })
}

export const _redirectToSupportCenter = __redirectToSupportCenter(window)

export const _redirectToSupportCenterPage = __redirectToSupportCenterPage(window)

export const redirectToSupportCenter = async (articleId, onRedirect) => {
  const [user] = useUser()
  const api = useApi()
  return _redirectToSupportCenter(user, api)(articleId, onRedirect)
}

export const redirectToSupportCenterPage = async (page, onRedirect) => {
  const [user] = useUser()
  const api = useApi()
  return _redirectToSupportCenterPage(user, api)(page, onRedirect)
}
