import React from 'react'
import styles from './Loading.module.css'

export const Loading = () => {
  return (
    <div data-testid="loading" className={styles.loadingInContainer}>
      <img src={'https://platform.cj.com/nav/static/logo.svg'} alt="CJ logo" />
    </div>
  )
}
