import { getBearerToken } from '@platform/platform-auth'

export const authenticatedFetch = async (url, { headers = {}, ...options } = {}) => {
  const token = await getBearerToken()

  return fetch(url, {
    headers: { ...headers, authorization: `Bearer ${token}` },
    ...options
  })
}
