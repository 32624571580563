import { getLocalRuntimeCookie, setLocalRuntimeCookie } from '../utils/cookie'

const localRuntimeParam = 'useLocalRuntime'
const localRuntimeKey = 'useLocalRuntime'

export const shouldUseLocalRuntime = () => {
  const { search } = location
  const params = new URLSearchParams(search)

  if (params.has(localRuntimeParam)) {
    const value = params.get(localRuntimeKey) === 'true'
    setLocalRuntimeCookie(value)
    return value
  }

  return getLocalRuntimeCookie()
}
