import React, { useEffect, useState, useContext } from 'react'
import { getUserId } from '@platform/platform-auth'
import { useUserApi } from '../api/userApi'
import { setCompanyCookie } from '../utils/cookie'
import { Loading } from '../Components/Loading'

export const UserContext = React.createContext([])

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const api = useUserApi()

  useEffect(() => {
    getUserId()
      .then(api.fetchUser)
      .then((u) => u && setUser(u))
  }, [setUser])

  async function setCompany(companyId) {
    if (user === null) {
      return console.error('Attempted to set Company before user initialized')
    }

    const company = await api.getCurrentCompany(user, companyId)
    if (!company) {
      return console.error('User is not associated with given company id')
    }

    setCompanyCookie(companyId)
    setUser({ ...user, currentCompany: company, language: api.getLanguage(user, company) })
  }

  return (
    <UserContext.Provider value={[user, setCompany]}>
      {user ? children : <Loading />}
    </UserContext.Provider>
  )
}

/**
 *
 * @returns {[user, setCompany]} An object representing the authenticated user including their
 * currently selected company, and a function to set the currently selected company
 */
export const useUser = () => {
  const [user, setCompany] = useContext(UserContext)
  return [user, setCompany]
}

export const useUser_ = () => {
  const [user] = useContext(UserContext)
  return user
}

export default useUser
