export default {
  accounts: {
    dev: 'https://accounts-frontend.elbo.dev.cj.com',
    prod: 'https://accounts.cj.com'
  },
  campaigns: {
    dev: 'https://campaigns.dev.cj.com',
    prod: 'https://campaigns.cj.com'
  },
  compliance: {
    prod: 'https://compliance.cj.com'
  },
  developers: {
    prod: 'https://developers.cj.com'
  },
  insights: {
    prod: 'https://insights.cj.com'
  },
  integrations: {
    dev: 'https://atossa.cats.dev.cj.com',
    prod: 'https://integrations.cj.com'
  },
  junction: {
    prod: 'https://junction.cj.com'
  },
  members: {
    lab101: 'https://member101.lab.cj.com',
    lab102: 'https://member102.lab.cj.com',
    lab103: 'https://member103.lab.cj.com',
    lab104: 'https://member104.lab.cj.com',
    lab105: 'https://member105.lab.cj.com',
    lab106: 'https://member106.lab.cj.com',
    lab107: 'https://member107.lab.cj.com',
    lab108: 'https://member108.lab.cj.com',
    lab109: 'https://member109.lab.cj.com',
    staging: 'https://members-staging.cj.com',
    prod: 'https://members.cj.com'
  },
  partners: {
    dev: 'https://partners-frontend.elbo.dev.cj.com',
    prod: 'https://partners.cj.com'
  },
  profile: {
    prod: 'https://profile.cj.com'
  },
  programs: {
    dev: 'https://programs.attribution.dev.cj.com',
    prod: 'https://programs.cj.com'
  },
  promocodes: {
    dev: 'https://promocodes.attribution.dev.cj.com',
    prod: 'https://promocodes.cj.com'
  },
  pubtag: {
    dev: 'https://pubtag.dev.cj.com',
    prod: 'https://pubtag.cj.com'
  }
}
