import React from 'react'
import useUser from '../state/useUser'
import { getCompanyIdFromCookie } from '../utils/cookie'
import useConfig from '../state/useConfig'
import { useRuntime } from '../state/useRuntime'
import { FormattedMessage, useIntl } from 'react-intl'
import useBreakpoint from '../state/useBreakpoint'

export const CompanyContextProvider = ({ children }) => {
  const { platformDomains } = useConfig()
  const [user, setCompany] = useUser()
  const { CompanyContextProvider: CCP } = useRuntime()
  const breakpoint = useBreakpoint()
  const intl = useIntl()

  return (
    <CCP
      user={user}
      setCompany={setCompany}
      platformDomains={platformDomains}
      getCompanyIdFromCookie={getCompanyIdFromCookie}
      FormattedMessage={FormattedMessage}
      breakpoint={breakpoint}
      intl={intl}
    >
      {children}
    </CCP>
  )
}

export default CompanyContextProvider
