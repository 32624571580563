import Cookies from 'js-cookie'

export const baseDomain = () =>
  window.location.hostname.endsWith('cj.com') ? 'cj.com' : window.location.hostname

export const setCompanyCookie = (id) => {
  Cookies.set(companyCookieKey, id, { domain: baseDomain(), expires: 1 })
}

export const getCompanyIdFromCookie = () => {
  return Number(Cookies.get(companyCookieKey)) || null
}

export const removeCompanyCookie = () => {
  Cookies.remove(companyCookieKey, { domain: baseDomain(), expires: 1 })
}

export const getImpersonatedUserId = () => {
  return Cookies.get(impersonationCookieKey) || null
}

export const getJsLanguageCookie = () => {
  return Cookies.get(jsLanguageCookieKey) || null
}

export const removeImpersonationCookie = () => {
  Cookies.remove(impersonationCookieKey, { domain: baseDomain(), expires: 1 })
}

export const removeUse2022Nav = () => {
  Cookies.remove(use2022Nav, { domain: baseDomain(), expires: 1 })
}

export const setUse2022Nav = (value) => {
  Cookies.set(use2022Nav, value, { domain: baseDomain(), expires: 1 })
}

export const setLocalRuntimeCookie = (value) => {
  Cookies.set(localRuntimeKey, value)
}

export const getLocalRuntimeCookie = () => {
  return Cookies.get(localRuntimeKey) === 'true'
}

const localRuntimeKey = 'useLocalRuntime'

const companyCookieKey = 'active_company'

const impersonationCookieKey = 'impersonated_user'

const jsLanguageCookieKey = 'jsLa'

const use2022Nav = 'use2022Nav'
