import React from 'react'
import { useRuntime } from '../state/useRuntime'

export const FormModal = (props) => {
  const { FormModal } = useRuntime()
  return <FormModal {...props} />
}

export const FormModalWithOptions = (props) => {
  const { FormModalWithOptions } = useRuntime()
  return <FormModalWithOptions {...props} />
}

export const FormModalTest = ({
  children,
  onSubmit,
  onRequestClose,
  submitText,
  disableSubmit,
  hideTitle,
  title = ''
}) => (
  <div data-testid="FormModalTestComponent">
    {(onSubmit || onRequestClose) && (
      <div data-testid="FormModalButtonContainer">
        {onRequestClose && (
          <button data-testid="form-cancel-button" onClick={onRequestClose}>
            Cancel
          </button>
        )}
        {onSubmit && (
          <button data-testid="form-submit-button" disabled={disableSubmit} onClick={onSubmit}>
            {submitText}
          </button>
        )}
      </div>
    )}
    {!hideTitle && <h1>{title}</h1>}
    {children}
  </div>
)
