import Cookies from 'js-cookie'

const navFeatureCookie = 'use2022Nav'
const navFeatureParam = 'use2022Nav'

export const shouldDisplay2022NavFactory =
  (_location, _fetch, _cookies) =>
  (featureHost = 'members.cj.com') => {
    const { search, hostname } = _location
    const params = new URLSearchParams(search)
    const featureRequestURL = `https://${featureHost}/affapi/features/VIEW_2022_PLATFORM_NAV`

    if (params.get(navFeatureParam) !== null) {
      return Promise.resolve(determineByNavFeatureParam(params, _cookies, hostname))
    } else if (_cookies.get(navFeatureCookie) !== undefined) {
      return Promise.resolve(determineByNavFeatureCookie(_cookies))
    } else {
      return determineByNavFeatureUrl(_fetch, featureRequestURL)
    }
  }

const determineByNavFeatureParam = (params, _cookies, hostname) => {
  const domain = hostname.endsWith('cj.com') ? 'cj.com' : hostname
  if (params.get(navFeatureParam) === 'true') {
    _cookies.set(navFeatureCookie, 'true', { domain })
    return true
  } else {
    _cookies.set(navFeatureCookie, 'false', { domain })
    return false
  }
}

const determineByNavFeatureCookie = (_cookies) => _cookies.get(navFeatureCookie) === 'true'

const determineByNavFeatureUrl = (_fetch, featureRequestURL) =>
  _fetch(featureRequestURL)
    .then((res) => res.json())
    .then((feature) => feature.isAvailableForAll)
    .catch((error) => {
      console.error('Error fetching navigation feature status: ', error.message)
    })

export default shouldDisplay2022NavFactory(window.location, window.fetch, Cookies)
