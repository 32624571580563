import useConfig from '../state/useConfig'
import { authenticatedFetch } from '../utils/client'
import { shouldUseLocalRuntime } from './shouldUseLocalRuntime'

function useApi() {
  const { platformDomains } = useConfig()

  return {
    async fetchCompanyContactId(userId, companyId) {
      const response = await authenticatedFetch(
        `${platformDomains.members}/affapi/users/${userId}/contact?companyId=${companyId}`
      )
      return response.json()
    },

    async fetchCompanyFeatures(companyId) {
      const response = await authenticatedFetch(
        `${platformDomains.members}/affapi/company/${companyId}/features`
      )
      return response.json().then((features) => new Set(features))
    },

    async fetchCompanyCountryCode(companyId) {
      const response = await authenticatedFetch(
        `${platformDomains.members}/affapi/companies/${companyId}/address`
      )
      return response.json().then((address) => address.country)
    },

    async fetchUserDetails(userId) {
      const response = await authenticatedFetch(
        `${platformDomains.members}/affapi/oauth/user/${userId}?includeActiveOnlyForBackwardsCompatibility=false`
      )
      return response.json()
    },

    async fetchUserPreferences(userId) {
      const response = await authenticatedFetch(
        `${platformDomains.members}/affapi/users/${userId}/preferences`
      )
      return response.json()
    },

    async fetchUserImpersonation(userId) {
      const response = await authenticatedFetch(
        `${platformDomains.members}/affapi/impersonations/${userId}`
      )
      return response.ok ? response.json() : null
    },

    async fetchBalance(cid) {
      const response = await authenticatedFetch(
        `${platformDomains.members}/affapi/company/${cid}/balance/`
      )
      return response.json()
    },

    async fetchMail(cid) {
      const response = await authenticatedFetch(
        `${platformDomains.members}/affapi/company/${cid}/mail/folder`
      )
      return response.json()
    },

    async fetchSupportCenterRedirect(cid) {
      const response = await authenticatedFetch(
        `${platformDomains.members}/affapi/company/${cid}/supportCenter`
      )
      return response.json()
    },

    async invalidateMemberSession() {
      await authenticatedFetch(`${platformDomains.members}/member/api/invalidate-session`, {
        method: 'PUT',
        credentials: 'include',
        redirect: 'error'
      })
    }
  }
}

export async function fetchTranslations(controller, staticAssetsPath, locale) {
  let response
  switch (locale) {
    case 'cs':
    case 'de':
    case 'en':
    case 'es':
    case 'fr':
    case 'ja':
    case 'pl':
    case 'pt':
    case 'zh':
      response = await fetch(`${staticAssetsPath}/translations/${locale}.json`, {
        signal: controller.signal
      })
      break
    default:
      response = await fetch(`${staticAssetsPath}/translations/en.json`, {
        signal: controller.signal
      })
  }

  return response.json()
}

export async function loadRuntimeScript() {
  if (window.NavRuntime) return Promise.resolve(window.NavRuntime)

  const navRuntimeScript = document.createElement('script')

  navRuntimeScript.src = shouldUseLocalRuntime()
    ? `http://localhost:3005/runtime.js`
    : `https://platform.cj.com/nav/v2/runtime/runtime.js`
  navRuntimeScript.type = 'text/javascript'
  navRuntimeScript.async = true
  document.body.appendChild(navRuntimeScript)

  return new Promise((resolve, reject) => {
    if (window.NavRuntime) return resolve(window.NavRuntime)

    navRuntimeScript.addEventListener('load', () => {
      resolve(window.NavRuntime)
    })
    navRuntimeScript.addEventListener('error', () => {
      console.error('Failed to load navigation runtime script.')
      reject()
    })
  })
}

export default useApi
