export class Errors extends Error {
  constructor(errors) {
    super()
    this.name = 'Errors'
    this.message = errors.map((error) => error.toString()).join('\n')
    this.errors = errors
  }
}

export class PlatformNavigationError extends Error {
  name = 'PlatformNavigationError'
}
