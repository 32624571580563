import React, { createContext, useContext, useEffect, useState } from 'react'

// window.matchMedia is undefined in Jest
const queries = window.matchMedia
  ? {
      xs: window.matchMedia('(max-width: 480px)'),
      sm: window.matchMedia('(min-width: 481px) and (max-width: 767px)'),
      md: window.matchMedia('(min-width: 768px) and (max-width: 991px)'),
      lg: window.matchMedia('(min-width: 992px)')
    }
  : {}

const getCurrentBreakpoint = () => {
  return Object.entries(queries).find(([_breakpoint, mq]) => mq.matches)?.[0]
}

export const BreakpointContext = createContext({})

export const BreakpointContextProvider = ({ children }) => {
  const [breakpoint, setBreakpoint] = useState(() => getCurrentBreakpoint())

  useEffect(() => {
    const updateBreakpoint = () => {
      const _breakpoint = getCurrentBreakpoint()
      if (breakpoint !== _breakpoint) setBreakpoint(_breakpoint)
    }

    Object.values(queries).map((mq) => mq.addEventListener('change', updateBreakpoint))

    return () =>
      Object.values(queries).map((mq) => mq.removeEventListener('change', updateBreakpoint))
  }, [breakpoint])

  return <BreakpointContext.Provider value={breakpoint}>{children}</BreakpointContext.Provider>
}

const useBreakpoint = () => {
  const breakpoint = useContext(BreakpointContext)
  return breakpoint
}

export default useBreakpoint
